table {
  width: 100%;
  margin-bottom: 60px;

  thead {
    th {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      background-image: $bg-gradient;
    }
  }

  tbody {
    width: 100%;

    tr {
      &:nth-child(odd) {
        background-color: map-get($colors, "white");
      }

      &:nth-child(odd) {
        background-color: map-get($colors, "light-gray");
      }

      &:hover {
        background-color: map-get($colors, "mid-gray");
        cursor: pointer;
      }
    }
  }

  th,
  td {
    height: 50px;
    padding: 10px;
    color: map-get($theme-colors, "font-accent");
    font-size: 12px;
  }

  th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

h3 {
  font-family: map-get($fonts, "main-font-type") !important;
  font-weight: 400 !important;
  font-size: map-get($fonts, "main-font-size") !important;
  color: map-get($theme-colors, "font-primary") !important;
  opacity: 0.56;
  margin: 13px 0 0 0;
}

h2 {
  font-family: map-get($fonts, "main-font-type");
  font-weight: 300 !important;
  font-size: map-get($fonts, "main-font-size") !important;
  color: map-get($theme-colors, "font-primary") !important;
  opacity: 0.86;
}

button {
  padding: 0.75rem 1.5rem;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;

  &.primary {
    background-color: map-get($colors, "mid-green");
    color: map-get($colors, "white");

    &:hover {
      background-color: lighten(map-get($colors, "mid-green"), 10);
    }
  }

  &.secondary {
    background-color: map-get($colors, "white");
    color: map-get($colors, "mid-green");

    &:hover {
      background-color: darken(map-get($colors, "white"), 10);
    }
  }
}

p {
  margin-bottom: 0 !important;
}
