$colors: (
  "black": #000,
  "white": #fff,
  "light-green": #7cb835,
  "mid-green": #79b334,
  "dark-green": #537a23,
  "light-gray": #f5f5f5,
  "mid-gray": #fff3e0,
);

$theme-colors: (
  "primary": map-get($colors, "mid-green"),
  "secondary": map-get($colors, "white"),
  "font-primary": map-get($colors, "black"),
  "font-accent": map-get($colors, "dark-green"),
  "button-primary": map-get($colors, "light-green"),
);

$fonts: (
  "main-font-type": Roboto,
  "main-font-size": 24px,
);

$bg-gradient: linear-gradient(rgb(255, 255, 255) 0%, rgb(229, 229, 229) 99%);
